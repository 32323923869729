.postal-code-form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.postal-code-form .input-text {
  background-color: var(--white);
  height: 56px;
  font-family: Muli;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  box-shadow: 0px 4px 20px 0px rgba(59, 57, 55, 0.08);
}

.postal-code-form .input-text input {
  padding: 18px 30px 5px 20px;
  height: 100%;
  border: none;
}

.postal-code-form .input-text .input-text__label {
  padding: 20px 15px 0 0;
  font-size: 1rem;
  left: 20px;
}

.postal-code-form__button {
  height: 56px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: var(--cucumber);
  color: var(--white);
  border: 0;
  box-shadow: 0px 4px 20px 0px rgba(59, 57, 55, 0.2);
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  font-family: 'Avenir-Black', 'Arial';
  font-weight: bolder;
  letter-spacing: 0.1rem;
}

@media (min-width: 480px) {
  .postal-code-form {
    flex-wrap: nowrap;
  }

  .postal-code-form .input-text {
    max-width: 247px;
    margin-right: 1rem;
  }

  .postal-code-form__button {
    width: 179px;
    margin-bottom: 0;
  }
}

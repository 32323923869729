.o-lang-selector {
  display: none;
}

@media (min-width: 992px) {
  .o-lang-selector {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;
    font-size: 0.875rem;
  }

  .o-lang-selector .o-lang-selector__content {
    position: relative;
    display: flex;
    cursor: pointer;
  }

  .o-lang-selector .o-lang-selector__current {
    text-align: right;
    margin-right: 8px;
    font-size: 0.875rem;
    font-family: Muli;
  }

  .o-lang-selector .o-lang-selector__icon {
    color: rgba(59, 57, 55, 0.2);
  }

  .o-lang-selector .o-lang-selector__dropdown {
    display: flex;
    width: 131px;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 12px;
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 0 0 8px;
  }

  .o-lang-selector .o-lang-selector__dropdown--hidden {
    display: none;
  }

  .o-lang-selector .o-lang-selector__item {
    list-style: none;
    cursor: pointer;
  }

  .o-lang-selector .o-lang-selector__item .o-lang-selector__button {
    color: #000;
    font-size: 14px;
    font-family: Muli;
    display: block;
    line-height: 2.5rem;
    padding-left: 16px;
    width: 100%;
    text-align: left;
  }

  .o-lang-selector .o-lang-selector__item:hover {
    background-color: #e6e6e6;
  }
}

@font-face {
  font-family: 'iconfont';
  src: url('../fonts/icon-font.woff?bp03x3') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.linkedin-logo:before {
  content: '\e91d';
}
.black-envelope:before {
  content: '\e91c';
}
.youtube-logo:before {
  content: '\e918';
}
.instagram-logo:before {
  content: '\e919';
}
.twitter-logo:before {
  content: '\e91a';
}
.x-twitter-logo:before {
  content: '\e91e';
}
.facebook-logo:before {
  content: '\e91b';
}
.ic-back-28:before {
  content: '\e90b';
}
.ic-card-28:before {
  content: '\e90c';
}
.ic-check-28:before {
  content: '\e90d';
}
.ic-delete-28:before {
  content: '\e90e';
}
.ic-download-28:before {
  content: '\e90f';
}
.ic-edit-28:before {
  content: '\e910';
}
.ic-hamburger-28:before {
  content: '\e911';
}
.ic-minus-28:before {
  content: '\e912';
}
.ic-phone-28:before {
  content: '\e913';
}
.ic-plus-28:before {
  content: '\e914';
}
.ic-products-28:before {
  content: '\e915';
}
.ic-truck-28:before {
  content: '\e916';
}
.ic-right-28:before {
  content: '\e917';
}
.ic-16-chevron-down:before {
  content: '\e900';
}
.ic-categories-grouceries-16:before {
  content: '\e901';
}
.ic-chevron-right-16:before {
  content: '\e902';
}
.ic-close-16:before {
  content: '\e903';
}
.ic-delete-16:before {
  content: '\e904';
}
.ic-edit-16:before {
  content: '\e905';
}
.ic-info-16:before {
  content: '\e906';
}
.ic-location-16:before {
  content: '\e907';
}
.ic-pass-hidden-16:before {
  content: '\e908';
}
.ic-pass-shown-16:before {
  content: '\e909';
}
.ic-star-16:before {
  content: '\e90a';
}

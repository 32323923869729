.food-alert {
  display: flex;
  padding: 5px 32px 8px;
  align-items: center;
  flex-direction: column;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  font-family: Muli;
  font-size: 1rem;
  text-align: center;
  min-height: 51px;
  justify-content: center;
}

.food-alert__content {
  margin: 0 auto;
  max-width: 1136px;
}

.food-alert__title {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin: 0;
}

.food-alert__message {
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
}

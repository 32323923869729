:root {
  --white-20: rgba(255, 255, 255, 0.2);
  --mobile-header-height: 81px;
  --mobile-logo-size: 173px;
}

.o-navbar-fluid {
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  box-shadow: 0 1px 0 rgba(12, 13, 14, 0.1), 0 1px 3px rgba(12, 13, 14, 0.1),
    0 4px 20px rgba(12, 13, 14, 0.035), 0 1px 1px rgba(12, 13, 14, 0.025);
}

.o-navbar {
  display: flex;
  height: 80px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.o-navbar .o-menu__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

.o-navbar .o-menu__wrapper .o-language {
  display: none;
}

.o-navbar .o-menu__icon {
  font-size: 28px;
  margin-left: 32px;
  cursor: pointer;
  color: var(--cucumber);
}

.o-logo {
  width: 230px;
  height: 40px;
  background-image: url('../img/mercadona-logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  align-self: center;
  margin: 0 auto;
  left: -10px;
  position: relative;
}

.o-menu {
  display: flex;
}

.o-menu .o-menu__item {
  display: none;
}

.o-navbar-fluid--open {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--navbar-background-mobile);
}

.o-navbar-fluid--open .o-navbar {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.o-navbar-fluid--open .o-navbar .o-menu__wrapper {
  height: 80px;
  flex-direction: row;
  border-bottom: 1px solid var(--white-20);
  background: var(--navbar-background-mobile);
  position: fixed;
  left: 0;
}

.o-navbar-fluid--open .o-navbar .o-menu__wrapper .o-logo {
  background-image: url('../img/mercadona-logo-white.svg');
  background-size: var(--mobile-logo-size);
  align-self: flex-start;
  background-position: center;
  margin-top: 20px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.o-navbar-fluid--open .o-navbar .o-menu__wrapper .o-language {
  display: none;
}

.o-navbar-fluid--open .o-navbar .o-menu__wrapper .o-menu__icon {
  color: var(--white);
  width: 28px;
}

.o-navbar-fluid--open .o-navbar .o-menu__wrapper.o-menu__wrapper--language {
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: fixed;
}

.o-navbar-fluid--open
  .o-navbar
  .o-menu__wrapper.o-menu__wrapper--language
  .o-logo {
  display: none;
}

.o-navbar-fluid--open
  .o-navbar
  .o-menu__wrapper.o-menu__wrapper--language
  .o-menu__icon {
  color: var(--white);
  width: 28px;
  flex: 1 0 auto;
}

.o-navbar-fluid--open
  .o-navbar
  .o-menu__wrapper.o-menu__wrapper--language
  .o-language {
  display: flex;
  color: var(--white);
  font-family: Muli;
  font-size: 1.5rem;
  flex: 1 0 auto;
  margin-right: 60px;
}

.o-navbar-fluid--open .o-navbar .o-menu {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: var(--mobile-header-height) 0 0;
}

.o-navbar-fluid--open .o-navbar .o-menu.o-menu--hidden {
  display: none;
}

.o-navbar-fluid--open .o-navbar .o-menu .o-menu__item {
  margin-top: 40px;
  font-size: 1.5rem;
  display: flex;
}

.o-navbar-fluid--open .o-navbar .o-menu .o-menu__item a {
  text-decoration: none;
  color: var(--white);
  font-family: Muli;
}

.o-navbar-fluid--open .o-navbar .o-lang-selector {
  display: flex;
  width: 100%;
}

.o-navbar-fluid--open .o-navbar .o-lang-selector .o-lang-selector__content {
  margin: 40px 0 0 40px;
  font-size: 1.5rem;
  color: var(--white);
  height: 100px;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content
  .o-lang-selector__current {
  font-family: Muli;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content
  .o-lang-selector__dropdown--hidden {
  display: none;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content.o-lang-selector__content--opened {
  margin-top: var(--mobile-header-height);
  width: 100%;
  height: 100%;
  padding-bottom: 80px;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content.o-lang-selector__content--opened
  .o-lang-selector__content {
  margin: 16px 0 0 40px;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content.o-lang-selector__content--opened
  .o-lang-selector__icon {
  display: none;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content.o-lang-selector__content--opened
  .o-lang-selector__current {
  display: none;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content.o-lang-selector__content--opened
  .o-lang-selector__dropdown--hidden {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0 0 40px;
  margin: 0;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content.o-lang-selector__content--opened
  .o-lang-selector__dropdown--hidden
  .o-lang-selector__item {
  margin-top: 40px;
}

.o-navbar-fluid--open
  .o-navbar
  .o-lang-selector
  .o-lang-selector__content.o-lang-selector__content--opened
  .o-lang-selector__dropdown--hidden
  .o-lang-selector__button {
  font-family: Muli;
}

@media (min-width: 992px) {
  .o-navbar {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1136px;
    padding: 0 32px;
  }

  .o-navbar .o-menu__wrapper {
    width: auto;
  }

  .o-navbar .o-menu__wrapper .o-logo {
    position: initial;
  }

  .o-navbar .o-menu__wrapper .o-menu__icon {
    display: none;
  }

  .o-navbar .o-menu {
    background-image: none;
    margin: 0;
    padding: 0;
  }

  .o-navbar .o-menu .o-menu__item {
    margin-left: 30px;
    display: flex;
    list-style: none;
    line-height: 2.5em;
    padding: 0;
  }

  .o-navbar .o-menu .o-menu__item a {
    text-decoration: none;
    color: var(--cucumber);
    font-family: Muli;
    font-size: 0.9375rem;
  }
}

@media (min-width: 1200px) {
  .o-navbar .o-menu .o-menu__item {
    margin-left: 40px;
  }
}

@import '_navbar.css';
@import '_footer.css';
@import '_icons.css';
@import '_button.css';
@import '_input.css';
@import '_notify-me.css';
@import '_fonts.css';
@import '_tooltip.css';
@import '_variables.css';
@import '_z-index.css';

* {
  box-sizing: border-box;
}

body {
  font-family: var(--secondary-font);
  background-color: var(--white);
  margin-top: 80px;
}

body.block_scroll {
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}

input::-ms-clear {
  display: none;
}

.o-header,
.o-info {
  display: none;
}

.section {
  display: flex;
  justify-content: center;
  padding: 3rem 2rem;
  flex-wrap: wrap;
}

.section--inverted {
  background: var(--white-cream-light);
  flex-direction: row-reverse;
}

.section__content {
  width: 100%;
  padding: 0 0 2rem 0;
}

.section__title {
  color: var(--black);
  font-size: 1.625rem;
  font-family: var(--primary-font);
  font-weight: 800;
  padding: 0 0 1.125rem 0;
  margin: 0;
}

.section__description {
  color: var(--smoked-70);
  font-size: 1.125rem;
  font-family: Muli;
  line-height: 1.5rem;
  padding: 0 0 0.5rem 0;
  margin: 0;
}

.section__link {
  color: var(--smoked-70);
  font-size: 1.125rem;
  font-family: Muli;
  line-height: 1.5rem;
  margin: 0;
}

.section__image {
  width: 100%;
  height: 302px;
  background-position: center;
  background-size: cover;
}

.section__link {
  color: var(--cucumber);
  display: inline-block;
  cursor: pointer;
}

@media (min-width: 768px) {
  .section {
    align-items: center;
  }

  .section--inverted .section__content {
    margin: 0 0 0 46px;
  }

  .section__content {
    margin-right: 46px;
  }

  .section__content,
  .section__image {
    max-width: 320px;
  }
}

@media (min-width: 992px) {
  .section--inverted .section__content {
    margin: 0 0 0 64px;
  }

  .section--inverted .section__title,
  .section--inverted .section__description {
    margin: 0;
  }

  .section__content {
    margin-right: 64px;
  }

  .section__content,
  .section__image {
    max-width: 420px;
  }

  .section__title,
  .section__description {
    max-width: 362px;
    margin: 0 0 0 auto;
  }
}

@media (min-width: 1200px) {
  .section__content,
  .section__image {
    max-width: 525px;
  }
}

.hero-header {
  background-color: #f4f1ec;
  background-image: url('./assets/banner2@1x.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 200%;
  padding: 40px 0;
}

.hero-header__wrapper {
  width: 100%;
  margin: 0 auto;
}

.hero-header__content {
  max-width: 510px;
  padding: 0 2rem 11rem;
  margin: 0 auto;
}

.hero-header__title {
  color: var(--black);
  font-family: var(--primary-font);
  font-size: 2.5rem;
  font-weight: 800;
  margin: 0;
}

.hero-header__description {
  color: var(--smoked-70);
  font-size: 1rem;
  font-family: Muli;
  line-height: 1.5em;
  max-width: 470px;
  margin: 0.5rem 0 1.5rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
  .hero-header {
    background-image: url('./assets/banner2@2x.jpg');
  }
}

@media (min-width: 480px) {
  .hero-header {
    height: 510px;
    padding: 50px 0;
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .hero-header {
    background-color: #f8f5ec;
    background-image: url('./assets/banner1@1x.jpg');
    background-position: 68%;
    background-size: cover;
    display: flex;
    align-items: center;
  }

  .hero-header__wrapper {
    max-width: 1114px;
    padding: 0 0 2.5rem 0;
    margin: 0;
  }

  .hero-header__content {
    max-width: 553px;
    padding: 0 2rem 2.5rem;
    margin: 0 0 auto auto;
  }

  .hero-header__title {
    font-size: 2.8rem;
  }
}

@media (min-width: 992px) {
  .hero-header {
    background-color: #f8f5ec;
    background-image: url('./assets/banner1@1x.jpg');
    background-position: 68%;
    background-size: cover;
    height: 605px;
  }

  .hero-header__wrapper {
    margin: 0 5%;
  }
}

@media (min-width: 1200px) {
  .hero-header {
    background-position: 60%;
  }

  .hero-header__wrapper {
    margin: 0 auto;
  }
}

@media (min-width: 1440px) {
  .hero-header__wrapper {
    max-width: 1400px;
  }
}

@media only screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min-width: 768px) and (min--moz-device-pixel-ratio: 2),
  only screen and (min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-width: 768px) and (min-device-pixel-ratio: 2),
  only screen and (min-width: 768px) and (min-resolution: 192dpi),
  only screen and (min-width: 768px) and (min-resolution: 2dppx) {
  .hero-header {
    background-image: url('./assets/banner1@2x.jpg');
  }
}

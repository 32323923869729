@import '../../../node_modules/@mercadona/mo.library.shop-ui/styles/variables.css';

:root {
  /* Colours */
  --navbar-background-mobile: rgba(34, 158, 107, 1);
  --cucumber-white-15: rgb(38, 184, 121);
  --cucumber-black-20: rgb(0, 137, 78);
  --egg-white-15: rgb(252, 195, 80);
  --egg-red-10: rgb(252, 166, 44);
  --tomato-white-15: rgb(223, 38, 38);
  --tomato-black-20: rgb(174, 0, 0);

  /* Fonts */
  --primary-font: PlayfairDisplay-ExtraBold, 'Times New Roman';
  --secondary-font: Muli, Arial;
}

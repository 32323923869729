.cookie-configuration-list {
  color: var(--smoked-70);
  font-size: 14px;
  line-height: 21px;
  padding-left: 16px;
  margin-top: 16px;
}

.cookie-configuration-list__cookie {
  list-style: none;
}

.cookie-configuration-list__cookie-name {
  font-weight: bold;
}

.cookie-configuration-list__cookie-info {
  list-style-type: disc;
}

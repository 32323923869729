.checkbox .checkbox__input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.checkbox .checkbox__input + .checkbox__label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

.checkbox .checkbox__input + .checkbox__label:after,
.checkbox .checkbox__input + .checkbox__label:before {
  content: '';
  height: 28px;
  width: 28px;
  cursor: pointer;
  border: 1px solid var(--smoked-20);
  border-radius: 100%;
  background-color: var(--white);
  display: inline-block;
  font-size: 28px;
  position: absolute;
  left: 0;
  top: 0;
}

.checkbox .checkbox__input:not(:checked) + .checkbox__label:after {
  opacity: 0;
}

.checkbox .checkbox__input:checked + .checkbox__label:after {
  content: '\e90d';
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  opacity: 1;
  background-color: var(--cucumber);
  border: 1px solid var(--cucumber);
  color: var(--white);
}

.checkbox .checkbox__input:checked:focus + .checkbox__label:before,
.checkbox .checkbox__input:not(:checked):focus + .checkbox__label:before {
  outline: #3b99fc auto 5px;
}

html,
body {
  font-size: 16px;
}

.old-input-text input {
  z-index: 1;
}

.old-input-text .old-input-text__label {
  z-index: 0;
}

.o-navbar-fluid {
  z-index: 2;
}

.o-menu__icon {
  z-index: 2;
}

.tooltip .tooltip-text {
  z-index: 1;
}

.o-modal {
  z-index: 2;
}

.cookie-banner {
  z-index: 2;
}

.modal {
  z-index: 2;
}

.cookie-banner-configuration__body {
  font-size: 14px;
  font-family: Muli;
  line-height: 21px;
}

.cookie-banner-configuration__explanation {
  font-size: 14px;
  font-family: Muli;
  line-height: 21px;
  padding-left: 1rem;
}

.cookie-banner-configuration__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cookie-banner-configuration__list-title {
  font-family: Muli;
  padding: 20px 0;
}

.cookie-banner-configuration__list-title:last-child {
  padding-bottom: 50px;
}

.cookie-banner-configuration__list-title-text {
  font-size: 18px;
  font-family: Muli;
  font-weight: bold;
  line-height: 22px;
}

.cookie-banner-configuration__list-title:first-child {
  border-bottom: 1px solid var(--white-cream);
}

.cookie-banner-configuration__list-title-box {
  display: flex;
  justify-content: space-between;
}

.cookie-banner-configuration__list-icon {
  font-size: 16px;
  margin-right: 3px;
}

.cookie-banner-configuration__list-status {
  font-size: 14px;
  font-weight: 600;
  height: 22px;
  line-height: 22px;
}

.cookie-banner-configuration__list-button-status {
  width: 28px;
  height: 28px;
  border: 1px solid var(--white-cream);
  border-radius: 100%;
}

.o-footer {
  font-family: Muli;
  background-color: #1c3037;
  color: rgba(255, 255, 255, 0.8);
}

.o-footer .o-footer-support {
  background-image: url('../img/call-center-web.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  width: 38px;
  height: 38px;
  margin-right: 12px;
}

.o-footer-phone-wrapper {
  padding-top: 16px;
}

.o-footer-phone-link {
  text-decoration: none;
}

.o-footer-phone {
  display: flex;
  align-items: center;
  font-size: 27px;
  font-weight: bold;
  color: white;
  font-family: sans-serif;
}

.o-footer h3 {
  font-size: 20px;
  margin-bottom: 8px;
  color: var(--white);
}

.o-footer .o-footer-contain {
  max-width: 1136px;
  margin: auto;
  box-sizing: border-box;
}

.o-footer .o-footer-logo {
  background-image: url('../img/mercadona-logo-white.svg');
  background-repeat: no-repeat;
  background-position: left;
  height: 30px;
  width: 100%;
  padding: 20px 0 40px;
  border-bottom: 1px solid rgba(251, 250, 249, 0.2);
  display: inline-block;
  vertical-align: top;
}

.o-footer .o-footer-links {
  padding: 20px 20px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.o-footer .o-footer-links-section {
  display: inline-block;
  vertical-align: top;
  flex: 1 1 100%;
}

.o-footer ul {
  list-style: none;
  padding: 0;
  line-height: 2em;
}

.o-footer ul a {
  font-family: Muli;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}

.o-footer ul a:hover {
  color: var(--white);
}

.o-footer .o-footer-call-center {
  font-size: 22px;
  border: 1px solid #fff;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  min-width: 190px;
  max-height: 30px;
  align-items: center;
}

.o-footer .o-footer-call-center-logo {
  background-image: url('../img/call-center-web.png');
  background-repeat: no-repeat;
  background-size: 28px;
  width: 28px;
  height: 28px;
}

.o-footer .o-footer-call-center p {
  line-height: 0;
}

.o-footer .o-footer-social {
  margin: 0;
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  max-width: 200px;
}

.o-footer .o-footer-social .icon {
  font-size: 1.5rem;
}

.o-footer-fluid {
  background-color: #1c3037;
  padding-bottom: 50px;
}

.o-footer-fluid .o-footer {
  color: var(--white-cream);
  display: flex;
  padding: 0 32px;
  font-size: 20px;
  flex-flow: column-reverse;
}

.o-footer-fluid .o-footer .o-copyright {
  margin: 40px 0;
  font-size: 18px;
  font-family: Muli;
}

.o-footer-fluid .o-footer .o-legal {
  margin: 0;
  list-style: none;
  padding: 40px 0;
  display: flex;
  border-bottom: 1px solid;
  flex-direction: column;
}

.o-footer-fluid .o-footer .o-legal li:not(:last-child) {
  margin-bottom: 32px;
}

.o-footer-fluid .o-footer .o-legal a {
  color: var(--white-cream);
  text-decoration: none;
}

.o-footer-fluid .o-footer .o-legal a:hover {
  opacity: 0.7;
}

@media (min-width: 480px) {
  .o-footer .o-footer-links-section {
    display: inline-block;
    vertical-align: top;
    flex: 1 1 50%;
  }
}

@media (min-width: 768px) {
  .o-footer .o-footer-links-section {
    display: inline-block;
    vertical-align: top;
    flex: 1 1 25%;
  }

  .o-footer-fluid .o-footer .o-legal a {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .o-footer .o-footer-links-section {
    display: inline-block;
    vertical-align: top;
    flex: 1 1 20%;
  }

  .o-footer-fluid .o-footer {
    font-size: 12px;
    max-width: 1136px;
    margin: 0 auto;
    justify-content: space-between;
    flex-flow: row;
  }

  .o-footer-fluid .o-footer .o-copyright {
    margin: 0;
    line-height: 4em;
    font-size: 12px;
  }

  .o-footer-fluid .o-footer li:not(:last-child) {
    margin-bottom: 0;
  }

  .o-footer-fluid .o-footer .o-legal {
    margin: 0;
    padding: 16px 0;
    flex-direction: row;
    border-bottom: none;
  }

  .o-footer-fluid .o-footer .o-legal li:not(:last-child) {
    margin-bottom: 0;
  }

  .o-footer-fluid .o-footer .o-legal a {
    margin-left: 24px;
    font-size: 12px;
    text-decoration: underline;
  }
}

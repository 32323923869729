.old-input-text {
  position: relative;
  padding-right: 10px;
  border-radius: 4px;
  font-family: Muli;
}

.old-input-text input {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  padding-left: 10px;
  outline: none;
  background-color: transparent;
  border: 1px solid var(--smoked-20);
  position: relative;
  box-sizing: content-box;
}

.old-input-text input.active {
  height: 42px;
  padding-top: 9px;
}

.old-input-text input.error {
  border: 1px solid var(--tomato);
}

.old-input-text .old-input-text__label {
  font-family: Muli;
  padding: 18px 10px 12px 0;
  color: var(--smoked-70);
  text-align: left;
  transition: transform 0.2s ease-out;
  transform-origin: 0 0;
  position: absolute;
  left: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.old-input-text .old-input-text__label.active {
  transform: translateY(-5px) scale(0.65);
  text-overflow: none;
  overflow: visible;
}

.old-input-text--small .old-input-text__label {
  padding: 12px 10px 12px 0;
}

.old-input-text--small input {
  height: 40px;
}

.old-input-text--small input.active {
  height: 32px;
  padding-top: 9px;
}

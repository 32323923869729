.button {
  border-radius: 4px;
  background-color: var(--cucumber);
  color: var(--white);
  font-family: Muli;
  font-size: 0.875rem;
  cursor: pointer;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
}

button:not(:focus-visible) {
  outline: none;
}

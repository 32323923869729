.tooltip {
  position: relative;
  display: inline-block;
  width: calc(100% - 3px);
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 253px;
  height: 32px;
  background-color: var(--tomato);
  text-align: center;
  font-size: 0.75rem;
  border-radius: 100px;
  position: absolute;
  font-family: Muli;
}

.tooltip .top {
  bottom: 105%;
  left: 50%;
  margin-left: -123px;
}

.tooltip .bottom {
  top: 105%;
  left: 50%;
  margin-left: -123px;
}

.tooltip .tooltip-text.active {
  visibility: visible;
}

.tooltip .tooltip-text p {
  margin: 10px;
}

.tooltip .tooltip-text > p:first-of-type {
  color: #fff;
}

.o-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(59, 57, 55, 0.2);
  text-align: center;
}

.o-modal .o-modal__container {
  background-color: var(--white);
  margin: 100px auto;
  max-width: 414px;
  height: 388px;
  width: 90%;
  display: inline-block;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.21);
  overflow: hidden;
}

.o-modal .o-modal__header {
  padding: 26px 0 0;
  display: block;
}

.o-modal .o-modal__header i {
  width: 12px;
  height: 12px;
  margin: 5px;
  background-size: contain;
  background-repeat: no-repeat;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 26px;
  color: var(--smoked-70);
}

.o-modal .o-modal__content {
  padding: 2px 40px;
}

.o-modal .o-modal__title {
  font-family: var(--primary-font);
  font-size: 1.375rem;
  font-weight: bold;
  line-height: 1.45;
  letter-spacing: -0.5px;
  margin: 0;
  color: var(--black);
}

.o-modal .o-modal__text {
  font-family: Muli;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: center;
  color: #3a3a3a;
}

.o-modal .old-input-text input {
  padding-left: 8px;
}

.o-modal .old-input-text:last-of-type {
  margin-top: 26px;
}

.o-modal .o-modal__button {
  margin: 42px auto;
  width: 222px;
  line-height: 40px;
}

.o-modal .o-modal__button:disabled {
  cursor: not-allowed;
  background-color: var(--smoked-20);
}

.o-modal .o-modal__button--success {
  margin: 16px auto 40px;
}

.o-modal .o-modal__image-wrapper {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

.o-modal .o-modal__image-wrapper .o-modal__image {
  width: 120px;
  height: auto;
  margin-top: 16px;
}

.o-modal .o-modal__title--success {
  margin-top: 24px;
}

:root {
  --error-color: var(--tomato);
}

.input-text {
  position: relative;
  margin-bottom: 18px;
}

.input-text input {
  width: 100%;
  padding: 18px 30px 5px 10px;
  outline: none;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid var(--smoked-20);
  position: relative;
  caret-color: var(--cucumber);
  height: 48px;
  z-index: 1;
  font-size: 0.875rem;
}

.input-text input:focus {
  border: solid 1px var(--smoked-40);
}

.input-text.input-text--error input {
  border-color: var(--error-color);
}

.input-text .input-text__label {
  padding: 16px 10px 0 0;
  color: var(--smoked-40);
  font-size: 0.875rem;
  text-align: left;
  transition: transform 0.2s ease-out;
  transform-origin: 0 0;
  position: absolute;
  left: 10px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.input-text .input-text__label.active {
  color: var(--smoked-70);
  transform: translateY(-5px) scale(0.75);
  text-overflow: none;
  overflow: visible;
}

.input-text .input-text__message {
  margin-top: 5px;
  text-align: left;
  font-size: 0.75rem;
  position: absolute;
}

.input-text .input-text__message.input-text__message--error {
  color: var(--error-color);
}

.input-text.input-text-big {
  margin-bottom: 40px;
}

.input-text.input-text-big input {
  width: 336px;
  height: 56px;
}

.input-text.input-text-big .input-text__label {
  font-size: 18px;
}

.input-text input:-webkit-autofill,
.input-text input:-webkit-autofill:hover,
.input-text input:-webkit-autofill:focus,
.input-text input:-webkit-autofill {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

.download-app {
  background-color: #f3f2ee;
  padding: 3rem 2rem;
  box-sizing: border-box;
  height: 100%;
}

.download-app__title {
  color: var(--black);
  font-family: var(--primary-font);
  font-size: 2.3rem;
  max-width: 405px;
  margin: 0 0 1.5rem 0;
}

.download-app__message {
  color: var(--smoked-70);
  font-size: 1rem;
  font-family: Muli;
  line-height: 1.5em;
  max-width: 350px;
  padding: 0 0 1rem 0;
  margin: 0;
}

.download-app__android,
.download-app__ios {
  height: 50px;
  margin-right: 0.5rem;
}

@media (min-width: 768px) {
  .download-app {
    height: 393px;
    background-image: url('./assets/download-app.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 45vw;
  }

  .download-app__android,
  .download-app__ios {
    height: 40px;
    margin-right: 0.5rem;
  }
}

@media (min-width: 992px) {
  .download-app {
    padding: 3rem 10%;
    background-position: 50vw;
  }
}

@media (min-width: 1200px) {
  .download-app {
    background-position: right;
    background-size: 60%;
  }
}

@media (min-width: 1920px) {
  .download-app {
    padding: 3rem 15%;
  }
}
